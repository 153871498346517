<messages>["../CreditLimit"]</messages>
<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :page.sync="pagination.page"
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    :footer-props="footerProps"
    :server-items-length="totalCount"
    :loading="isLoading"
    :no-data-text="noDataText (isLoading)"
    :no-results-text="noResultsText (isLoading)"
    class="elevation-1">
    <template #item="props">
      <tr>
        <td v-if="isAdmin">
          <client-link :id="props.item.clientId"/>
        </td>
        <td>
          {{ formatDate (props.item.startDate) }}
        </td>
        <td :class="{'red--text': isEnded (props.item.endDate)}">
          {{ formatDate (props.item.endDate) }}
        </td>
        <td class="text-right">
          {{ formatMoneyAmount (props.item.creditLimit.value, props.item.creditLimit.currency) }}
        </td>
        <td>
          {{ props.item.comment ? props.item.comment : emptyMark }} <!--- TODO: Shorten? --->
        </td>
        <td>
          <user-link
            :id="props.item.creatorUser.id"
            :user-name="props.item.creatorUser.name"
            :link="isAdmin"/>
        </td>
        <td>
          <user-link
            v-if="props.item.terminatorUser"
            :id="props.item.creatorUser.id"
            :user-name="props.item.creatorUser.name"
            :link="isAdmin"/>
          <span v-else>
            {{ emptyMark }}
          </span>
        </td>
        <td v-if="isAdmin">
          <action-buttons
            :value="isActionButtonsActive (props.item.id)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.id) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
  import {mapGetters} from 'vuex'

  import {EmptyMark} from '@/app/utils/string'
  import {formatDate} from '@/app/core/mixins/DatetimeHelper'

  import ActionButtons from '@/app/core/components/ActionButtons'
  import ClientLink from '@/app/core/components/ClientLink'
  import UserLink from '@/app/core/components/UserLink'

  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import {footerProps, noDataText, noResultsText} from '@/app/core/mixins/PaginationComponent'

  export default {
    name: 'CreditLimitTable',

    components: {
      ClientLink,
      UserLink,
      ActionButtons
    },

    mixins: [actionButtonsHelper],

    props: {
      items: {
        type: Array,
        default: null
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      pagination: {
        type: Object,
        default: null
      },
      totalCount: {
        type: Number,
        default: 0
      }
    },

    data () {
      return {
        emptyMark: EmptyMark
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'hasAllOfPermissions'
      ]),

      // default footer properties, which can be used for `VDataTable` component
      footerProps,

      isAdmin () {
        return this.hasAllOfPermissions (['DoAccounting', 'ManageAllEntities'])
      },

      paginationInternal: {
        get () {
          return this.pagination
        },
        set (newVal) {
          this.$emit ('update:pagination', newVal)
        }
      },

      headers () {
        return [
          ...this.isAdmin
            ? [{
              text: this.$t ('list.header.clientId'),
              value: 'clientId'
            }]
            : [],
          {
            text: this.$t ('list.header.startDate'),
            value: 'startDate'
          },
          {
            text: this.$t ('list.header.endDate'),
            value: 'endDate'
          },
          {
            text: this.$t ('list.header.creditLimit'),
            value: 'creditLimit',
            align: 'right'
          },
          {
            text: this.$t ('list.header.comment'),
            value: 'comment',
            sortable: false
          },
          {
            text: this.$t ('list.header.creatorUser'),
            value: 'creatorUser'
          },
          {
            text: this.$t ('list.header.terminatorUser'),
            value: 'terminatorUser'
          },
          ...this.isAdmin
            ? [{
              text: this.$t ('list.header.action'),
              sortable: false
            }]
            : []
        ]
      }
    },

    methods: {
      formatDate,

      // default text properties, which can be used for `VDataTable` component
      noDataText,
      noResultsText,

      isEnded (endDate) {
        const d = new Date ()
        return d > new Date (endDate)
      },

      getActionButtons (item) {
        return [
          {
            action: 'end',
            actionArg: item,
            icon: 'delete',
            tooltip: this.$t ('list.end.btn'),
            disabled: this.isEnded (item.endDate),
            color: 'error'
          }
        ]
      },

      processActionButton (button) {
        switch (button.action) {
          case 'end':
            this.$emit (
              'end',
              button.actionArg
            )
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      }
    }
  }
</script>
