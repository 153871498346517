<messages>["./CreditLimit"]</messages>
<template>
  <base-layout>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <v-row justify="space-between">
            <v-col cols="12" sm="6">
              <div>
                <div
                  v-t="`list.title`"
                  class="text-h5"/>
                <p
                  v-t="`list.subTitle`"
                  class="cgwng-subheading"/>
                <v-btn
                  v-if="isViewAdmin"
                  :to="{name: 'creditlimit.create'}">
                  <v-icon left>
                    add
                  </v-icon>
                  {{ $t ('list.createNew') }}
                </v-btn>
              </div>
            </v-col>

            <!-- filter -->
            <v-col cols="12" sm="6">
              <v-row>
                <v-col v-if="isViewAdmin" cols="12">
                  <client-select
                    v-model="clientFilter"
                    :label="$t ('list.clients')"
                    multiple
                    nullable
                    for-accounting/>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-switch
                    v-model="showEnded"
                    class="mt-1 ml-2 pa-0"
                    :label="$t ('list.showEnded')"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <confirmation-dialog
            v-model="endConfirmation.isVisible"
            is-delete
            :is-loading="endConfirmation.isLoading"
            :custom-btn-text="$t ('list.end.btn')"
            :headline="endConfirmation.headline"
            :cancel-action="endConfirmation.cancelCb"
            @ok="endConfirmation.callback">
            <i18n v-if="endConfirmation.limit" path="list.end.text">
              <client-link
                :id="endConfirmation.limit.clientId"
                place="client"/>
              <span place="value">
                {{ formatMoneyAmount (endConfirmation.limit.creditLimit.value, endConfirmation.limit.creditLimit.currency) }}
              </span>
            </i18n>
          </confirmation-dialog>
        </v-card-title>

        <v-card-text>
          <credit-limit-table
            :items="limits"
            :total-count="totalCount"
            :pagination.sync="pagination"
            :is-loading="isLoading"
            @end="onEnd"/>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions, mapMutations, mapGetters} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import ClientSelect from '@/app/core/components/ClientSelect'
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import ClientLink from '@/app/core/components/ClientLink'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'

  import CreditLimitTable from './components/CreditLimitTable'

  export default {
    name: 'CreditLimitList',

    components: {
      BaseLayout,
      ClientSelect,
      CreditLimitTable,
      ConfirmationDialog,
      ClientLink
    },

    mixins: [paginationMixins],

    data () {
      return {
        showEnded: false,
        clientFilter: [],
        isLoading: false,
        limits: [],
        cachedLimits: [],
        totalCount: 0,
        frontendListOperations: true,
        endConfirmation: {
          isVisible: false,
          isLoading: false,
          headline: '',
          limit: null,
          cancelCb: () => {},
          callback: () => {}
        }
      }
    },

    computed: {
      items () {
        return this.limits
      },
      ...mapGetters ('auth', [
        'hasAllOfPermissions'
      ]),

      isViewAdmin () {
        return this.hasAllOfPermissions (['DoAccounting', 'ManageAllEntities'])
      }
    },

    watch: {
      showEnded () {
        this.listCreditLimit ()
      },

      clientFilter () {
        this.listCreditLimit ()
      }
    },

    created () {
      this.pagination.sortBy = 'startDate'
      this.pagination.rowsPerPage = 10
      this.pagination.descending = true
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      newConfirmation (override) {
        this.endConfirmation = {
          isVisible: false,
          isLoading: false,
          headline: '',
          limit: null,
          cancelCb: () => {},
          callback: () => {},
          ...this.endConfirmation,
          ...override
        }
      },

      onEnd (limit) {
        this.endConfirmation = {
          limit,
          isVisible: true,
          isLoading: false,
          callback: () => this.end (limit),
          cancelCb: () => {
            this.newConfirmation ({
              isVisible: false,
              isLoading: false,
              headline: '',
              limit: null,
              cancelCb: () => {},
              callback: () => {}
            })
          },
          headline: this.$t ('list.end.headline')
        }
      },

      end (limit) {
        this.newConfirmation ({isLoading: true})
        this.fetchData ({
          op: 'account/end-temp-credit-limit',
          params: {
            id: limit.id
          },
          cb: () => {
            this.displaySuccessMessage (this.$t ('list.end.success'))
            this.listCreditLimit (true)
            this.newConfirmation ({
              isVisible: false,
              headline: '',
              limit: null,
              cancelCb: () => {},
              callback: () => {}
            })
          },
          cbFinal: () => {
            this.newConfirmation ({isLoading: false})
          }
        })
      },

      list () {
        this.listCreditLimit ()
      },

      async listCreditLimit (force = false) {
        this.isLoading = true

        if (this.frontendListOperations) {
          if (force || !(this.cachedLimits.length > 0)) {
            [this.cachedLimits] = await this.requestLimits ()
            this.totalCount = this.cachedLimits.length
          }
          this.performListOperation ()
        } else {
          [this.limits, this.totalCount] =
            await this.requestLimits ({
              ...this.getPaginationForRequest (),
              clientIds: this.clientFilter,
              includeEnded: this.showEnded
            })
        }

        this.isLoading = false
      },

      performListOperation () {
        let c = this.cachedLimits.slice ()
        let t = 0
        if (this.clientFilter && this.clientFilter.length > 0) {
          c = this.cachedLimits.filter ((l) =>
            this.clientFilter.includes (l.clientId)
          )
        }

        if (!this.showEnded) {
          const d = new Date ()

          c = c.filter ((l) => d < new Date (l.endDate))
        }

        t = c.length

        if (this.pagination.sortBy) {
          c.sort ((a, b) => {
            let valA = a[this.pagination.sortBy]
            let valB = b[this.pagination.sortBy]

            let cmpRes

            if (this.pagination.sortBy === 'creditLimit') {
              if (valA === null || valB === null) {
                cmpRes = valA === null ? -1 : 1
              } else {
                cmpRes = valA?.value > valB?.value ? 1 : valA?.value === valB?.value ? 0 : -1
              }
            } else if (this.pagination.sortBy === 'creatorUser' || this.pagination.sortBy === 'terminatorUser') {
              valA = valA.name.toLocaleLowerCase ()
              valB = valB.name.toLocaleLowerCase ()
              cmpRes = valA > valB ? 1 : valA === valB ? 0 : -1
            } else {
              cmpRes = valA > valB ? 1 : valA === valB ? 0 : -1
            }
            return this.pagination.descending ? -cmpRes : cmpRes
          })
        }

        // slice
        if (this.pagination.page > 0 && this.pagination.rowsPerPage > 0) {
          const start = (this.pagination.page - 1) * this.pagination.rowsPerPage
          const end = start + this.pagination.rowsPerPage
          c = c.slice (start, end)
        }

        this.limits = c
        this.totalCount = t
      },

      async requestLimits (params) {
        const {list, totalCount} = await this.fetchData ({
          op: 'account/list-temp-credit-limits',
          params
        })

        return [list || [], totalCount || 0]
      }
    }
  }
</script>
